import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";

const NewsPage = (props) => {
 /*  const { frontmatter } = data.markdownRemark;
  const { edges } = data.allMarkdownRemark; */

  return <Layout location={'news'}>news news news</Layout>;
};

NewsPage.propTypes = {};

export default NewsPage;

/* export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
 */
